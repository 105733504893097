import React from "react"
import { colors } from "../utils/theme"
import ContentLayout from "./ContentLayout"
import Collapsible from "./Collapsible"
import css from "@emotion/css"
import { useIntl } from "gatsby-plugin-intl"

export default () => {
  const intl = useIntl()

  return (
    <>
      <ContentLayout bg={colors.primary.light}>
        <div
          data-sal="slide-up"
          data-sal-duration="300"
          data-sal-delay="0"
          data-sal-easing="ease"
        >
          <h2
            css={css`
              margin-bottom: 44px;
            `}
          >
            {intl.formatMessage({ id: "faq" })}
          </h2>
        </div>
        <div
          data-sal="slide-up"
          data-sal-duration="300"
          data-sal-delay="0"
          data-sal-easing="ease"
        >
          <Collapsible
            title={intl.formatMessage({ id: "faq-#1-title" })}
            description={intl.formatMessage({ id: "faq-#1-description" })}
          />
          <Collapsible
            title={intl.formatMessage({ id: "faq-#2-title" })}
            description={intl.formatMessage({ id: "faq-#2-description" })}
          />
          <Collapsible
            title={intl.formatMessage({ id: "faq-#3-title" })}
            description={intl.formatMessage({ id: "faq-#3-description" })}
          />
          <Collapsible
            title={intl.formatMessage({ id: "faq-#4-title" })}
            description={intl.formatMessage({ id: "faq-#4-description" })}
            last
          />
        </div>
      </ContentLayout>
    </>
  )
}
