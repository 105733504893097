import React from "react"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import ArgumentsSection from "../components/ArgumentsSection"
import AvailableBrowsersSection from "../components/AvailableBrowsersSection"
import FAQSection from "../components/FAQSection"
import ReassuranceSection from "../components/ReassuranceSection"
import { colors } from "../utils/theme"
import { injectIntl } from "gatsby-plugin-intl"

const IndexPage = ({ intl }) => (
  <PageLayout navColor={colors.primary.light}>
    <SEO title={intl.formatMessage({ id: "title" })} />
    <Hero bg={colors.primary.light} />
    <ArgumentsSection />
    <AvailableBrowsersSection />
    <FAQSection />
    <ReassuranceSection />
  </PageLayout>
)

export default injectIntl(IndexPage)
