import React from "react"
import ContentLayout from "./ContentLayout"
import { css } from "@emotion/core"
import { graphql, useStaticQuery } from "gatsby"
import { breakpoints } from "../utils/theme"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"

import { colors } from "../utils/theme"
import TypeformButton from "./TypeformButton"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "heroScreenshot.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const intl = useIntl()

  return (
    <div>
      <ContentLayout bg={props.bg}>
        <div
          data-sal="fade"
          data-sal-duration="400"
          data-sal-delay="0"
          data-sal-easing="ease"
        >
          <h1
            css={css`
              font-weight: bold;
              font-size: 40px;
              color: ${colors.primary.darker};
            `}
          >
            {intl.formatMessage({ id: "catchphrase" })}
          </h1>
        </div>
        <div
          data-sal="fade"
          data-sal-duration="400"
          data-sal-delay="200"
          data-sal-easing="ease"
        >
          <TypeformButton />
        </div>

        <div
          css={css`
            width: 100%;
            margin-top: 80px;
          `}
        >
          <Img
            css={css`
              border-radius: 5px;
              box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
            `}
            fluid={data.file.childImageSharp.fluid}
            alt="zenshare app extension"
          />
        </div>
        <div
          data-sal="fade"
          data-sal-duration="100"
          data-sal-delay="0"
          data-sal-easing="ease"
        >
          <div
            css={css`
              margin-top: 64px;
            `}
          >
            <h3
              css={css`
                font-weight: bold;
                color: ${colors.primary.darker};
                text-align: center;
              `}
            >
              {intl.formatMessage({ id: "hero-image-caption-title" })}
            </h3>
            <h3
              css={css`
                font-weight: normal;
                font-style: italic;
                margin-top: 8px;
                color: ${colors.primary.darker};
                text-align: center;
              `}
            >
              {intl.formatMessage({ id: "hero-image-caption-description" })}
            </h3>
          </div>
        </div>
      </ContentLayout>
    </div>
  )
}
