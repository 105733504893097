import React from "react"
import { colors } from "../utils/theme"
import { css } from "@emotion/core"
import * as typeformEmbed from "@typeform/embed"
import { useIntl } from "gatsby-plugin-intl"

const TypeformButton = () => {
  const intl = useIntl()
  return (
    <div
      onClick={() =>
        typeformEmbed.makePopup("https://cycodron.typeform.com/to/BwXcI5", {
          mode: "popup",
          autoOpen: true,
        })
      }
      css={css`
        margin-top: 24px;
        text-decoration: none;
        background: ${colors.primary.dark};
        font-size: 16px;
        font-weight: 700;
        color: white;
        border-radius: 100px;
        padding: 12px 26px;
        width: fit-content;
        &:hover {
          cursor: pointer;
          background: ${colors.primary.dark90};
        }
      `}
    >
      {intl.formatMessage({ id: "typeform-button-request-access" })}
    </div>
  )
}

export default TypeformButton
