import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import ContentLayout from "./ContentLayout"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { breakpoints } from "../utils/theme"

export default () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      chrome: file(relativePath: { eq: "chrome.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      firefox: file(relativePath: { eq: "firefox.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      brave: file(relativePath: { eq: "brave.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <ContentLayout paddingBottom={"160px"}>
        <div
          data-sal="slide-up"
          data-sal-duration="300"
          data-sal-delay="0"
          data-sal-easing="ease"
        >
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}
          >
            <h3
              css={css`
                @media (max-width: ${breakpoints.md}) {
                  text-align: center;
                }
              `}
            >
              {intl.formatMessage({ id: "section-browsers-title" })}
            </h3>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-top: 32px;
            `}
          >
            <h4
              css={css`
                color: #ccc;
              `}
            >
              {intl.formatMessage({ id: "section-browsers-available" })}
            </h4>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-top: 16px;
            `}
          >
            <div
              css={css`
                width: 70%;
                display: flex;
                align-items: center;
                justify-content: space-around;

                @media (max-width: ${breakpoints.xs}) {
                  flex-direction: column;
                  gap: 24px;
                }

                @media (max-width: ${breakpoints.sm}) {
                  gap: 16px;
                }
              `}
            >
              <div
                css={css`
                  width: 150px;
                `}
              >
                <Img
                  fluid={data.chrome.childImageSharp.fluid}
                  alt="Available on Google Chrome"
                />
              </div>
              <div
                css={css`
                  width: 150px;
                `}
              >
                <Img
                  fluid={data.firefox.childImageSharp.fluid}
                  alt="Available on Firefox"
                />
              </div>
              <div
                css={css`
                  width: 150px;
                `}
              >
                <Img
                  fluid={data.brave.childImageSharp.fluid}
                  alt="Available on Brave"
                />
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    </>
  )
}
