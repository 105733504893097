import React from "react"
import ContentLayout from "./ContentLayout"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import css from "@emotion/css"
import { colors, breakpoints } from "../utils/theme"
import Img from "gatsby-image"
import TypeformButton from "./TypeformButton"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "heroScreenshot.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const intl = useIntl()

  return (
    <>
      <div
        data-sal="slide-up"
        data-sal-duration="300"
        data-sal-delay="0"
        data-sal-easing="ease"
      >
        <ContentLayout paddingTop="64px">
          <div
            css={css`
            display: flex;
            background-color: ${colors.primary.light};
            border-radius: 10px;
            height 344px;
            overflow: hidden;

            @media (max-width: ${breakpoints.md}) {
              flex-direction: column;
              height: auto;
            }
          `}
          >
            <div
              css={css`
                width: 50%;
                display: flex;
                align-items: center;

                @media (max-width: ${breakpoints.md}) {
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  overflow: hidden;
                  width: 100%;
                `}
              >
                <Img
                  css={css`
                    width: 100%;
                    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
                    border-radius: 0 10px 10px 0;
                    @media (max-width: ${breakpoints.md}) {
                      border-radius: 10px 10px 0 0;
                    }
                  `}
                  fluid={data.image.childImageSharp.fluid}
                  alt="Share your company news asynchronously"
                />
              </div>
            </div>
            <div
              css={css`
                width: 40%;
                padding-top: 64px;
                padding-left: 32px;
                padding-right: 32px;

                @media (max-width: ${breakpoints.md}) {
                  padding-top: 48px;
                  padding-bottom: 48px;
                  width: 100%;
                }
              `}
            >
              <h3>{intl.formatMessage({ id: "reassurance-title" })}</h3>
              <div
                css={css`
                  margin-top: 16px;
                `}
              >
                <p>
                  {intl.formatMessage({
                    id: "reassurance-description",
                  })}
                </p>
                <TypeformButton />
              </div>
            </div>
          </div>
        </ContentLayout>
      </div>
    </>
  )
}
