import React, { useState } from "react"
import css from "@emotion/css"
import * as styles from "./collapsible.module.css"
import { FaChevronDown } from "react-icons/fa"

const Collapsible = props => {
  const [expanded, setExpanded] = useState(false)
  const handleAction = () => {
    if (expanded) {
      setExpanded(false)
    } else {
      setExpanded(true)
    }
  }

  return (
    <div
      onClick={handleAction}
      className={styles.collapsible}
      css={css`
        margin-bottom: ${props.last ? "0" : "64px"};
        cursor: pointer;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <h3>{props.title}</h3>
        <div
          css={css`
            height: "40px";
            width: "40px";
          `}
        >
          <FaChevronDown className={expanded ? styles.bottom : styles.top} />
        </div>
      </div>
      <div className={expanded ? styles.expanded : styles.collapsed}>
        {props.description}
      </div>
    </div>
  )
}

export default Collapsible
