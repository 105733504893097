import React from "react"
import ContentLayout from "./ContentLayout"
import { css } from "@emotion/core"
import { graphql, useStaticQuery } from "gatsby"
import { colors, breakpoints } from "../utils/theme"
import Img from "gatsby-image"
import newsGif from "../gifs/engagingNews.gif"
import { useIntl } from "gatsby-plugin-intl"

export default () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      availableBrowsers: file(relativePath: { eq: "availableBrowsers.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      team: file(relativePath: { eq: "teamSection.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      {/* Section title */}
      <div
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
      >
        <ContentLayout paddingBottom="0px">
          <h2>
            <span
              css={css`
                color: ${colors.primary.base};
              `}
            >
              {intl.formatMessage({ id: "arguments-section-title-part-1" })}
            </span>
            {intl.formatMessage({ id: "arguments-section-title-part-2" })}
            <span
              css={css`
                color: ${colors.primary.base};
              `}
            >
              {intl.formatMessage({ id: "arguments-section-title-part-3" })}
            </span>
          </h2>
        </ContentLayout>
      </div>

      {/* Section item: engaging news */}
      <div
        data-sal="slide-up"
        data-sal-duration="300"
        data-sal-delay="0"
        data-sal-easing="ease"
      >
        <ContentLayout paddingTop="64px" paddingBottom="0px">
          <div
            css={css`
            display: flex;
            background-color: ${colors.primary.light};
            border-radius: 10px;
            height 344px;
            overflow: hidden;
            
            @media (max-width: ${breakpoints.md}) {
              flex-direction: column;
              height: auto;
            }
          `}
          >
            <div
              css={css`
                width: 60%;

                @media (max-width: ${breakpoints.md}) {
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  background-image: url(${newsGif});
                  background-size: contain;
                  background-repeat: no-repeat;
                  width: 100%;
                  height: 0;
                  padding-top: 50.56%;
                  border-radius: 0 10px 10px 0;
                  margin-top: 32px;
                  background-position: center;

                  @media (max-width: ${breakpoints.md}) {
                    margin-top: 0px;
                    border-radius: 10px 10px 0 0;
                  }
                `}
              ></div>
            </div>

            <div
              css={css`
                width: 40%;
                height: fit-content;
                padding-top: 64px;
                padding-left: 32px;
                padding-right: 32px;

                @media (max-width: ${breakpoints.md}) {
                  width: 100%;
                  justify-content: center;
                  padding-top: 48px;
                  padding-bottom: 48px;
                }
              `}
            >
              <h3>
                {intl.formatMessage({ id: "section-announcement-title" })}
              </h3>
              <div
                css={css`
                  margin-top: 16px;
                `}
              >
                <p>
                  {intl.formatMessage({
                    id: "section-announcement-description",
                  })}
                </p>
              </div>
            </div>
          </div>
        </ContentLayout>
      </div>
      {/* Section item: engaging news */}
      <div
        data-sal="slide-up"
        data-sal-duration="300"
        data-sal-delay="0"
        data-sal-easing="ease"
      >
        <ContentLayout paddingTop="64px">
          <div
            css={css`
            display: flex;
            background-color: ${colors.primary.light};
            border-radius: 10px;
            height 344px;
            overflow: hidden;

            @media (max-width: ${breakpoints.md}) {
              flex-direction: column-reverse;
              height: auto;
            }
          `}
          >
            <div
              css={css`
                width: 40%;
                padding-top: 64px;
                padding-left: 32px;
                padding-right: 32px;

                @media (max-width: ${breakpoints.md}) {
                  width: 100%;
                  padding-top: 48px;
                  padding-bottom: 48px;
                }
              `}
            >
              <h3>{intl.formatMessage({ id: "section-team-title" })}</h3>
              <div
                css={css`
                  margin-top: 16px;
                `}
              >
                <p>
                  {intl.formatMessage({
                    id: "section-team-description",
                  })}
                </p>
              </div>
            </div>

            <div
              css={css`
                width: 60%;
                display: flex;
                align-items: center;
                @media (max-width: ${breakpoints.md}) {
                  width: 100%;
                }
              `}
            >
              <div
                css={css`
                  width: 100%;
                  height: 280px;

                  @media (max-width: ${breakpoints.md}) {
                    height: auto;
                  }
                `}
              >
                <Img
                  css={css`
                    width: 100%;
                    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
                    border-radius: 10px 0 0 10px;
                  `}
                  fluid={data.team.childImageSharp.fluid}
                  alt="Showcase team activity, birthday, new comers onboarding"
                />
              </div>
            </div>
          </div>
        </ContentLayout>
      </div>
    </>
  )
}
